import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'sampleIds', 'taskIds'];

  initialize() {
    window.console.log('SampleResultsForm Controller Initialized!');
  }

  preview() {
    const params = {};

    $(this.inputTargets).each((_i, input) => {
      const $input = $(input);
      const name = /sample_results\[(\w+)\]/.exec($input.prop('name'))[1];
      const value = $input.val();

      params[name] = value;
    });

    $.ajax({
      method: 'GET',
      url: '/sample_results/preview',
      data: {
        sample_results: params,
      },
      dataType: 'script',
    });
  }

  addAllTasks(event) {
    event.preventDefault();
    this.$taskIdsField.val(this.taskIds);
    this.$taskIdsField[0].dispatchEvent(new Event('select2:select'));
  }

  addAllSamples(event) {
    event.preventDefault();
    this.$sampleIdsField.val(this.sampleIds);
    this.$sampleIdsField[0].dispatchEvent(new Event('select2:select'));
  }

  get taskIds() {
    return $.map(this.$taskIdsField[0].options, option => $(option).val());
  }

  get $taskIdsField() {
    return $(this.taskIdsTarget);
  }

  get sampleIds() {
    return $.map(this.$sampleIdsField[0].options, option => $(option).val());
  }

  get $sampleIdsField() {
    return $(this.sampleIdsTarget);
  }

  get $element() {
    return $(this.element);
  }
}
