import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['count', 'checkbox', 'selectCurrText'];

  connect() {
    this._setSelectionState();
  }

  // triggers
  selectAllCheckboxChanged(e) {
    this.checkboxTargets.forEach((el) => { el.checked = e.target.checked; });
    this._setSelectionState();
    e.target.dispatchEvent(new Event('selectall:change'));
  }

  singleCheckboxChanged() {
    this._setSelectionState();
  }
  // end triggers

  // sub methods
  _setSelectionState() {
    this.selectedCount = this.checkboxTargets.filter(el => el.checked).length;
    this.countTarget.innerText = this.selectedCount;
    if (this.selectedCount > 0) {
      this.selectCurrTextTarget.classList.remove('hidden');
    } else {
      this.selectCurrTextTarget.classList.add('hidden');
    }
  }
}
