import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'match']

  connect() {
    this.change();
  }

  change() {
    console.log(this.selectTarget.value);
    this.matchTargets.forEach((target) => {
      if (target.dataset.value.split(' | ').includes(this.selectTarget.value)) {
        target.classList.remove('hidden');
        target.querySelectorAll('input,select').forEach((input) => {
          input.removeAttribute('disabled');
        });
      } else {
        target.classList.add('hidden');
        target.querySelectorAll('input,select').forEach((input) => {
          input.setAttribute('disabled', 'disabled');
        });
      }
    });
  }
}
