import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['template'];

  connect() {
    console.log('Dynamic fields controller connected!');
    console.log(this.templateTarget); // Should log the template element
  }

  add(event) {
    event.preventDefault();
    console.log("Add button clicked");
    console.log(this.templateTarget); // Should log the template element
    event.currentTarget.insertAdjacentHTML(
      "beforebegin",
      this.templateTarget.innerHTML.replace(
        /__CHILD_INDEX__/g,
        new Date().getTime().toString()
      )
    );
  }
}
