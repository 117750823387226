import { Controller } from 'stimulus';

// Use this controller rather than .tooltips class to survive turbolinks js re-render
export default class extends Controller {
  static targets = ['wrapper', 'dialog', 'backdrop'];

  initialize() {
    // event bind for modal open
    document.addEventListener('modal:open', this.openModal.bind(this));
    document.addEventListener('modal:close', this.closeModal.bind(this));

    // close modal when clicking outside of dialog
    $('#modal-backdrop div').click(this.closeModal.bind(this));
  }

  openModal(event) {
    event.preventDefault();
    this._setup();
    this._showDialog();
    this._showBackdrop();
  }

  closeModal(e) {
    if (e) e.preventDefault();
    this._hideDialog();
    this._hideBackdrop();
    this._teardown();
  }

  _setup() {
    // close drawer
    $('#drawer').addClass('hidden');
    // modal teardown
    this.wrapperTarget.classList.remove('hidden');
    this.wrapperTarget.classList.add('absolute', 'w-full', 'h-screen');
  }

  _teardown() {
    // modal teardown
    this.wrapperTarget.classList.add('hidden');
    this.wrapperTarget.classList.remove('absolute', 'w-full', 'h-screen');
    // open drawer
    $('#drawer').removeClass('hidden');
  }

  _showDialog() {
    this.dialogTarget.classList.remove('hidden');
    this.dialogTarget.classList.add('opacity-100', 'translate-y-0', 'sm:scale-100');
    this.dialogTarget.classList.remove('opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95');
  }

  _hideDialog() {
    this.dialogTarget.classList.add('opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95');
    this.dialogTarget.classList.remove('opacity-100', 'translate-y-0', 'sm:scale-100');
    this.dialogTarget.classList.add('hidden');
  }

  _showBackdrop() {
    this.backdropTarget.classList.remove('hidden');
    this.backdropTarget.querySelector('div').classList.remove('hidden');
    this.backdropTarget.classList.add('opacity-100');
    this.backdropTarget.classList.remove('opacity-0');
  }

  _hideBackdrop() {
    this.backdropTarget.classList.add('opacity-0');
    this.backdropTarget.classList.remove('opacity-100');
    this.backdropTarget.querySelector('div').classList.add('hidden');
    this.backdropTarget.classList.add('hidden');
  }
}
