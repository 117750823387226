import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    const table = $('#results_update_multiple_table');
    this.enableCellNaviation(table);
  }

  enableCellNaviation(table) {
    const arrow = {
      left: 37,
      up: 38,
      right: 39,
      down: 40,
    };
    const enter = 13;

    // select all on focus
    // works for input elements, and will put focus into
    // adjacent input or textarea. once in a textarea,
    // however, it will not attempt to break out because
    // that just seems too messy imho.
    table.find('input').keydown((e) => {
      // shortcut for key other than arrow keys
      if ($.inArray(e.which, [arrow.left, arrow.up, arrow.right, arrow.down, enter]) < 0) {
        return;
      }

      const input = e.target;
      const td = $(e.target).closest('td');
      let moveTo = null;

      /* eslint-disable default-case */
      switch (e.which) {
        case arrow.left:
        {
          if (input.selectionStart === 0) {
            moveTo = td.prev('td:has(input,textarea)');
          }
          break;
        }
        case arrow.right:
        {
          if (input.selectionEnd === input.value.length) {
            moveTo = td.next('td:has(input,textarea)');
          }
          break;
        }

        case arrow.up:
        case arrow.down:
        case enter:
        {
          const tr = td.closest('tr');
          const pos = td[0].cellIndex;

          let moveToRow = null;
          if (e.which === arrow.down || e.which === enter) {
            moveToRow = tr.next('tr');
          } else if (e.which === arrow.up) {
            moveToRow = tr.prev('tr');
          }

          if (moveToRow.length) {
            moveTo = $(moveToRow[0].cells[pos]);
          }

          break;
        }
      }
      /* eslint-enable default-case */

      if (moveTo && moveTo.length) {
        e.preventDefault();

        moveTo.find('input,textarea').each((i, input2) => {
          $('.datepicker').hide();
          input2.focus();
          input2.select();
        });
      }
    });
  }
}
