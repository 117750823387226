import { Controller } from 'stimulus';

export default class extends Controller {
  build() {
    this.width = parseInt(this.element.dataset.columns, 10);
    let index = 0;
    this.inputTargets.forEach((el) => {
      if (index >= this.width) index = 0;
      el.dataset.column = index;
      index += 1;
    });
  }

  get inputTargets() {
    return Array.from(this.element.querySelectorAll('[data-excel-paste-target="input"]'));
  }

  paste(e) {
    e.preventDefault();
    this.build();
    const data = this._formatClipboard(e.clipboardData.getData('text'));
    let offset = null;
    const index = [0, 0];
    this.inputTargets.forEach((el) => {
      const col = parseInt(el.dataset.column, 10);
      if (el === e.target && offset === null) offset = col;
      if (col === 0 && !(offset === null) && !(el === e.target)) {
        index[0] = 0;
        index[1] += 1;
      }
      if (offset !== null && (offset + index[0]) === col) {
        let row = null;
        let val = null;
        /* eslint-disable */
        if ((row = data[index[1]]) !== undefined && (val = row[index[0]]) !== undefined) {
        /* eslint-enable */
          this._setValueWithUndoOption(el, val);
          this._emitEvents(el);
          index[0] += 1;
        }
      }
    });
  }

  _emitEvents(el) {
    el.dispatchEvent(new Event('change'));
  }

  // sets value programmatically while maintaining the option to undo via ctrl-z[:w
  _setValueWithUndoOption(el, val) {
    el.value = val;
  }

  _formatClipboard(rawData) {
    return this._cleanupData(rawData.split('\n').map(row => row.split('\t')));
  }

  _cleanupData(data) {
    const firstCol = data[0];
    if (!firstCol) return data;

    const width = firstCol.length;
    const cleanedData = [];
    data.forEach((row) => {
      if (row.length === width) cleanedData.push(row);
    });
    const lastRow = data[data.length - 1];
    if (lastRow.length === 1 && (lastRow[0] === '' || lastRow[0] === null || lastRow[0] === undefined)) {
      data.pop();
    }

    return cleanedData;
  }
}
