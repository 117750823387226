import { Controller } from 'stimulus';

let timer = null;

export default class extends Controller {

  initialize() {
    console.log('Sample Request Index Filters Controller Initialized!');
  }

  updateQuery() {
    $('.saved-search').val('');
    this.submitForm();
  }

  submitForm() {
    this.showLoading();
    const query_params = $(this.element).serialize();

    console.log(query_params);

    var base_url = $('#save-search-link').attr('href').split('?')[0];
    $('#save-search-link').attr('href', base_url + '?' + query_params);
    // window.history.pushState(null, null, `/sample_requests? + ${query_params}`);

    Rails.fire(this.element, 'submit');
  }

  showLoading() {
    $('.loading-row').removeClass('hidden');
    $('.sample-request-rows').remove();
  }

  clearSearch() {
    $("[data-action='change->sample-request-index-filters#updateQuery']").val('');
    $("[data-action='input->sample-request-index-filters#restartTimer']").val('');
    $("#status")[0].selectedIndex = 0;
    this.updateQuery();
  }

  restartTimer() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.updateQuery();
    }, 300);
  }
}
