import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['body'];

  initialize() {
    window.console.log('GeneratedReport Controller Initialized!');
  }

  connect() {
    this.initializeConsumer();
  }

  initializeConsumer() {
    consumer.subscriptions.create(
      {
        channel: 'GeneratedReportsChannel',
        reportable_type: this.reportableType,
        reportable_id: this.reportableId,
        report_type: this.reportType,
      },
      {
        received: (data) => {
          this.update(data.id);
        },
      },
    );
  }

  update(id) {
    $.ajax({
      method: 'GET',
      url: `/generated_reports/${id}`,
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      complete: (response) => {
        const html = response.responseText;
        this.renderBody(html);
      },
    });
  }

  renderBody(html) {
    const bodyHtml = $(html).find('[data-target="generated-report.body"]');
    this.$body.html(bodyHtml);
  }

  get reportableType() {
    return $(this.element).data('reportable-type');
  }

  get reportableId() {
    return $(this.element).data('reportable-id');
  }

  get reportType() {
    return $(this.element).data('report-type');
  }

  get $body() {
    return $(this.bodyTarget);
  }
}
