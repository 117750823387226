import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  initialize() {
    window.console.log('Sortable Cocoon Controller Initialized!');
  }

  connect() {
    $(this.element).sortable({
      axis: 'y',
      handle: '[name=sort_handle]',
      tolerance: 'pointer',
      items: '> [data-target="sortable-cocoon.item"]',
      cursor: 'move',
      stop: () => {
        $.each(this.itemTargets, (i, item) => {
          $(item).find('.position').val(i);
        });
      },
    });
  }
}
