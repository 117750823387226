import { Controller } from 'stimulus';

export default class extends Controller {
  markSamples(event) {
    const selectedSampleNum = event.target.value;
    const taskId = this.getGroupId(event);
    const inactiveSamples = this.getSamples(taskId, 'inactive', this.getOrdinal(selectedSampleNum));
    this.clickLinks(inactiveSamples);
    event.target.selectedIndex = 0;
  }

  unmarkSamples(event) {
    const selectedSampleNum = event.target.value;
    const taskId = this.getGroupId(event);
    const activeSamples = this.getSamples(taskId, 'active', this.getOrdinal(selectedSampleNum));
    this.clickLinks(activeSamples);
    event.target.selectedIndex = 0;
  }

  markTasks(event) {
    const selectedTaskId = event.target.value;
    const sampleId = this.getGroupId(event);
    const inactiveTasks = this.getTasks(sampleId, 'inactive', selectedTaskId);
    this.clickLinks(inactiveTasks);
    event.target.selectedIndex = 0;
  }

  unmarkTasks(event) {
    const selectedTaskId = event.target.value;
    const sampleId = this.getGroupId(event);
    const activeTasks = this.getTasks(sampleId, 'active', selectedTaskId);
    this.clickLinks(activeTasks);
    event.target.selectedIndex = 0;
  }

  copyToTask(event) {
    const selectedTaskId = event.target.value;
    const taskId = this.getGroupId(event);
    this.copyTask(taskId, selectedTaskId);
    event.target.selectedIndex = 0;
  }

  copyFromTask(event) {
    const selectedTaskId = event.target.value;
    const taskId = this.getGroupId(event);
    this.copyTask(selectedTaskId, taskId);
    event.target.selectedIndex = 0;
  }

  copyToSample(event) {
    const selectedSampleId = event.target.value;
    const sampleId = this.getGroupId(event);
    this.copySample(sampleId, selectedSampleId);
    event.target.selectedIndex = 0;
  }

  copyFromSample(event) {
    const selectedSampleId = event.target.value;
    const sampleId = this.getGroupId(event);
    this.copySample(selectedSampleId, sampleId);
    event.target.selectedIndex = 0;
  }

  getGroupId(event) {
    return event.target.dataset.groupId;
  }

  loopAndClickSamples(loopLinks, selectedTaskId, status) {
    loopLinks.each((index, value) => {
      const ordinal = $(value).attr('href').split('ordinal=')[1].split('&sample_id')[0];
      const sampleId = $(value).attr('href').split('&sample_id=')[1].split('&status')[0];
      const samplesToClick = this.getSamples(selectedTaskId, status, ordinal, sampleId);
      this.clickLinks(samplesToClick);
    });
  }

  loopAndClickTasks(loopLinks, selectedSampleId, status) {
    loopLinks.each((index, value) => {
      const ordinal = $(value).attr('href').split('ordinal=')[1].split('&sample_id')[0];
      const taskId = $(value).attr('href').split('/tasks/')[1].split('/results')[0];
      const tasksToClick = this.getTasks(selectedSampleId, status, taskId, ordinal);
      this.clickLinks(tasksToClick);
    });
  }

  getSamples(taskId, status, ordinal = '', sampleId = '') {
    if (sampleId.length) {
      return $(`a[href^='/tasks/${taskId}/results'][href*='?ordinal=${ordinal}&sample_id=${sampleId}&status=${status}']`);
    } if (ordinal.toString().length && !Number.isNaN(ordinal)) {
      return $(`a[href^='/tasks/${taskId}/results'][href*='?ordinal=${ordinal}&'][href*='&status=${status}']`);
    }
    return $(`a[href^='/tasks/${taskId}/results'][href*='&status=${status}']`);
  }

  getTasks(sampleId, status, taskId = '', ordinal = '') {
    if (ordinal.length) {
      return $(`a[href*='/tasks/${taskId}/results'][href*='?ordinal=${ordinal}&sample_id=${sampleId}&status=${status}']`);
    } if (taskId.length) {
      return $(`a[href*='/tasks/${taskId}/results'][href*='&sample_id=${sampleId}&status=${status}']`);
    }
    return $(`a[href*='/results'][href*='&sample_id=${sampleId}&status=${status}']`);
  }

  copyTask(task1, task2) {
    const inactiveSamples = this.getSamples(task1, 'inactive');
    const activeSamples = this.getSamples(task1, 'active');
    // remove existing results from copyTo task that aren't copyFrom task
    this.loopAndClickSamples(inactiveSamples, task2, 'active');
    // add results that don't exist in copyTo task that are in copyFrom task
    this.loopAndClickSamples(activeSamples, task2, 'inactive');
  }

  copySample(sample1, sample2) {
    const activeTasks = this.getTasks(sample1, 'active');
    const inactiveTasks = this.getTasks(sample1, 'inactive');
    // remove existing results from copyTo task that aren't copyFrom task
    this.loopAndClickTasks(inactiveTasks, sample2, 'active');
    // add results that don't exist in copyTo task that are in copyFrom task
    this.loopAndClickTasks(activeTasks, sample2, 'inactive');
  }

  clickLinks(links) {
    links.each((index, value) => {
      $(value)[0].click();
    });
  }

  getOrdinal(selectedSampleNum) {
    return Number(selectedSampleNum) - 1;
  }
}
