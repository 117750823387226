import { Controller } from 'stimulus';

require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/droppable');
require('jquery-ui/ui/widgets/draggable');

export default class extends Controller {
  initialize() {
    window.console.log('AttachmentController Initialized!');

    this.sortableSelector = '#attachment_folder .attachables';
    this.droppableSelector = '.droppable';
    this.draggableSelector = '.drag_handle';

    document.addEventListener('attachable:added', this.connect.bind(this));
  }

  connect() {
    $(this.draggableSelector).draggable({
      revert: true,
      start(event, ui) {
        // Disable dropping in related folder.
        const element = ui.helper.closest('.attachable').find('.ui-droppable');
        element.droppable('disable');
      },
      stop(event, ui) {
        // Re-enable dropping in related folder.
        const element = ui.helper.closest('.attachable').find('.ui-droppable');
        element.droppable('enable');
      },
    }); // draggable()

    $(this.droppableSelector).droppable({
      tolerance: 'pointer',
      accept: '.attachable',
      activeClass: 'can_drop',
      hoverClass: 'will_drop',
      drop(event, ui) {
        const target = $(event.target).closest('.attachable');

        const folderId = target.data('id');
        const element = ui.helper.closest('.attachable');

        if (target[0] === element[0]) { return; } // Do not drop into self.

        const data = element.data();
        const { attachableType } = data;
        const url = `/${attachableType}s/${data.id}`;
        const attachableData = {};

        attachableData[attachableType] = { attachment_folder_id: folderId };
        $(this.sortableSelector).sortable('enable');

        // Simply update the folder_id for the related attachable.
        $.ajax({
          method: 'PUT',
          url,
          data: attachableData,
          dataType: 'js',
          complete() {
            // element is the item being dragged.
            element.detach();
          },
        });
      }, // drop()
    }); // droppable()
  }

  removeAttachable(event) {
    $(event.target).closest('.attachable').detach();
  }
}
