import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'form'];

  connect() {
    console.log('inline editable form connected!');
    this.setOriginalValue();
  }

  setOriginalValue() {
    const field = $(this.fieldTarget);
    field.show().focus();
    field.select();
    const originalValue = field.val();
    field.data("originalValue", originalValue);
  }

  submitIfUpdated(){
    const field = $(this.fieldTarget);
    const form = $(this.formTarget);
    const currentValue = field.val();
    const originalValue = field.data('originalValue');
    const container = field.closest('.inline-editable-form-container');
    const partial = container.data('partial');

    if (currentValue !== originalValue) {
      this.submitForm(event);
    } else {
      this.fetchPartial(container, partial);
    }
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      this.triggerEscapeAction();
    }
  }

  triggerEscapeAction() {
    const field = $(this.fieldTarget);
    field.val(field.data("originalValue"));
    field.blur();
    this.fieldTarget.dispatchEvent(new Event('change', { 'bubbles': true }));
  }

  submitForm(event) {
    event.preventDefault(); // Prevent the default form submission

    // Submit the form
    Rails.fire(this.formTarget, 'submit');
  }

  async fetchPartial(container, partial) {
    console.log(partial);
    try {
      const response = await fetch(`/${partial}`);
      const html = await response.text();
      // Update the DOM with the fetched partial
      container.html(html);
    } catch (error) {
      console.error('Error fetching partial:', error);
    }
  }

}
