import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log('freeze columns connected!')
    this.element.addEventListener("scroll", this.freezeColumns.bind(this));
  }

  freezeColumns() {
    const frozenColumns = this.element.querySelectorAll(".sticky");
    frozenColumns.forEach((column) => {
      column.style.transform = `translateX(${this.element.scrollLeft}px)`;
    });
  }
}