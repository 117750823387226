import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle', 'disable']

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this._toggle();
    this._disable();
  }

  _toggle() {
    this.toggleTargets.forEach(element => element.classList.toggle('hidden'));
  }

  toggleShow() {
    this.toggleTargets.forEach(element => element.classList.remove('hidden'));
  }

  toggleHide(e) {
    this.toggleTargets.forEach((element) => {
      if (element.contains(e.target) && element !== e.target) return;

      element.classList.add('hidden');
    });
  }

  _disable() {
    this.disableTargets.forEach((element) => {
      element.disabled = !element.disabled;
    });
  }
}
