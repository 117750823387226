import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link'];

  initialize() {
    console.log('click on focus initialized!');
  }

  clickLink() {
    this.linkTarget.dispatchEvent(new Event('click', { 'bubbles': true }))
  }

}
