import { Controller } from 'stimulus';
import tippy, { followCursor } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Use this controller rather than .tooltips class to survive turbolinks js re-render
export default class extends Controller {
  initialize() {
    const title = this.element.title || this.element.dataset.title;

    // tooltip options
    const options = {
      content: title,
      animation: 'scale-subtle',
      allowHTML: true,
    };

    const pluginType = this.element.dataset.tooltipPlugin;
    if (pluginType !== undefined) {
      options.followCursor = pluginType;
      options.plugins = [followCursor];
    }

    this.element.removeAttribute('title');
    if (title !== undefined && title.length > 1) {
      tippy(this.element, options);
    }
  }
}
