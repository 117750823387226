import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']

  initialize() {
    window.console.log('ResourceQuery Controller Initialized!');
  }

  seeMore(event) {
    event.preventDefault();
    const page = $(event.target).data('page');
    $('#see_more .fa-spin').removeClass('hidden').show();
    this.preview(page);
  }

  preview(page) {
    $.ajax({
      method: 'GET',
      url: `${this.$form.attr('action')}/preview?see_more=true&page=${page}`,
      data: this.$form.serialize(),
      dataType: 'script',
    });
  }

  get $form() {
    return $(this.formTarget);
  }
}
