import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['parents', 'children'];

  all_children = $('*[data-target="dynamic-select.children"]').html();

  initialize() {
    console.log('Dyanmic select initialized');
    this.filterChildren();
  }

  filterChildren() {
    console.log('Children filtered');
    const selected_parent = $(this.parentsTarget).find(':selected').val();
    const options = $(this.all_children).filter(`optgroup[label='${selected_parent}']`).html();
    $(this.childrenTarget).html(options);
  }
}
