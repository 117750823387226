import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['counter'];

  initialize() {
    window.console.log('UnseenNotifications Controller Initialized!');
  }

  connect() {
    this.initializeConsumer();
    this.updateCounter(true);
  }

  updateCounter(pageLoad) {
    if ($('[data-controller=notifications]').length > 0) {
      // Behavior has to be different for the inbox page, because NOtifications are
      // marked as :seen when they are added to the page, so they are not marked as
      // NEW (:seen == false) when the page is refreshed.
      const $unseen = $('.notification [data-target="notifications.unseen"]');
      let count = $unseen.length;
      // When this is called from ActionCable, notifications-controller.js makes
      // an Ajax (updateCounterFromServer) call to add the new Notification element
      // to the Inbox, so on initial page load, the unseen count will be the number
      // of unseen elements, and when this AJAX call is made, it is the number of
      // unessn elements + 1, because this executes much faster than an AJAX call.
      if (!pageLoad) { count += 1; }

      this.updateCounterValue(count);
    } else {
      this.updateCounterFromServer();
    }
  }

  updateCounterFromServer() {
    $.ajax({
      method: 'GET',
      url: '/notifications?scope=unseen',
      data: { notification: { seen: true } },
      dataType: 'json',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      complete: (data) => {
        const count = data.responseJSON.length;
        this.updateCounterValue(count);
      },
    });
  }

  updateCounterValue(unseenCount) {
    if (unseenCount === 0) {
      this.$counter.hide();
    } else {
      this.$counter.text(unseenCount).show();
    }
  }

  initializeConsumer() {
    consumer.subscriptions.create(
      {
        channel: 'NotificationsChannel',
        id: this.userId,
      },
      {
        received: () => {
          this.updateCounter();
        },
      },
    );
  }

  get $counter() {
    return $(this.counterTarget);
  }

  get userId() {
    return $(this.element).data('userId');
  }
}
