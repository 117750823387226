// Include a filter-with data attribute on your "element" targets, add to a text
// field (or any field with a value) an action that calls #filterElements.

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['element', 'queryField'];

  initialize() {
    window.console.log('Filter Controller Initialized!');
  }

  filter() {
    $(this.elementTargets).each((i, target) => {
      const element = $(target);

      if (this.query === '') {
        element.show();
      } else {
        const name = element.data('filter-with');
        const regex = new RegExp(this.query, 'i');

        if (name.match(regex)) {
          element.show();
        } else {
          element.hide();
        }
      }
    });
  }

  get query() {
    return this.$queryField.val();
  }

  get $queryField() {
    return $(this.queryFieldTarget);
  }
}
