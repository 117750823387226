import { Controller } from 'stimulus';

export default class extends Controller {
  // static targets = ['urlToScrape', 'match']

  connect() {
    console.log('Scraper controller connected!');
  }

  scrapeDataRow(event) {
    const input = $(event.target);
    const container = input.closest('tr');
    const spinner = input.closest('td').find('.scrape-spinner');
    this.scrapeData(input, container, spinner);
  }

  scrapeDataForm(event) {
    const input = $(event.target);
    const container = input.closest('form');
    const spinner = input.closest('div').find('.scrape-spinner');
    this.scrapeData(input, container, spinner);
  }

  scrapeData(input, container, spinner) {
    const url_to_scrape = input.val();
    const orderQuantity = container.find('.order-quantity');

    const urlArr = ['homedepot.com', 'amazon.com', 'walmart.com', 'menards.com', 'tractorsupply.com', 'harborfreight.com', 'mcmaster.com', 'uline.com', 'grainger.com']
    const urlIsIncluded = urlArr.some(url => url_to_scrape.includes(url));

    if (!urlIsIncluded) return;

    spinner.show();

    $.ajax({
      method: 'POST',
      url: `/scrape_urls`,
      data: {
        url_to_scrape,
      },
      dataType: 'json',
      success(data) {
        for (const [key, value] of Object.entries(data.scrape_results)) {
          const field_to_fill = container.find(`.${key}`);
          const cleaned_value = value.trim().replace(/(\r\n|\n|\r)/gm, "");
          field_to_fill.val(cleaned_value);
          if (field_to_fill[0] !== undefined){
            console.log(`field-to-fill = ${cleaned_value}`);
            field_to_fill[0].dispatchEvent(new Event('change', { 'bubbles': true }));
          }
        };
        spinner.hide();
        orderQuantity[0].dispatchEvent(new Event('input', { 'bubbles': true }))
      },
    });
  }

}
