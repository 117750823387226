import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.reload();
  }

  reload() {
    if (this.element.dataset.bottomWrapper) {
      this._targettedReload();
    } else {
      this._globalReload();
    }
  }

  _targettedReload() {
    const wrapper = document.querySelector(this.element.dataset.bottomWrapper);
    const bottom = document.querySelector(this.element.dataset.bottom);
    if (!bottom || !wrapper) return;

    setTimeout(() => {
      wrapper.scrollTop = bottom.offsetTop;
    }, 0);
  }

  _globalReload() {
    const bottom = document.querySelector(this.element.dataset.bottom || '#bottom');
    setTimeout(() => {
      bottom.scrollIntoView();
    }, 0);
  }
}
