import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field'];

  _setupAutocomplete() {
    const { url } = this.fieldTarget.dataset;
    const labelField = this.fieldTarget.dataset.label;

    $(this.fieldTarget).select2({
      theme: 'bootstrap',
      width: '100%',
      ajax: {
        url,
        dataType: 'json',
        data(params) { return { query: params.term }; },
        processResults(data) {
          return {
            results: $.map(data, obj => ({ id: obj.id, text: obj[labelField] })),
          };
        },
      },
    });
  }

  initialize() {
    this._setupAutocomplete();
  }
}
