import { Controller } from 'stimulus';

export default class extends Controller {
  expand() {
    if (this.state === 'closed') {
      this.open();
    } else if (this.state === 'open') {
      this.close();
    }
  }

  close() {
    this.state = 'closing';

    $(this.element).find('[name=full]').hide(500, () => {
      this.state = 'closed';
    });
  }

  open() {
    this.state = 'opening';

    $(this.element).find('[name=full]').show(500, () => {
      this.state = 'open';
    });
  }

  currentRevisionAction(event) {
    const target = $(event.target);

    switch (target.attr('name')) {
      case 'view':
        window.open(`/document_revisions/${this.currentRevisionId}`);

        target.val('');
        break;
      case 'download':
        window.open(`/document_revisions/${this.currentRevisionId}/download`);

        target.val('');
        break;
      case 'mark_reviewed':
        $.ajax({
          url: `/document_revisions/${this.currentRevisionId}`,
          method: 'PUT',
          dataType: 'script',
          data: {
            document_revision: {
              reviewed_at: new Date(),
            },
          },
          success() {
            window.toastr.success('Review date has been updated successfully');
          },
        });

        break;
      case 'edit':
        window.location = `/document_revisions/${this.currentRevisionId}/edit`;
        break;
      case 'delete':
        if (window.confirm('Are you sure?')) {
          $.ajax({
            url: `/document_revisions/${this.currentRevisionId}`,
            method: 'DELETE',
          });
        } else {
          target.val('');
        }

        break;
      default:
        throw new Error('DocumentRevsion action not recognized');
    }
  }

  get $element() {
    return $(this.element);
  }

  get documentId() {
    return this.data.get('id');
  }

  get currentRevisionId() {
    return this.data.get('current-revision-id');
  }

  get state() {
    return this.data.get('state');
  }

  set state(state) {
    this.data.set('state', state);
  }
}
