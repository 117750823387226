import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    window.console.log('NotificationTrigger Controller Initialized!');
  }

  create() {
    $.ajax({
      method: 'POST',
      url: '/notification_triggers',
      data: this.formData,
      dataType: 'html',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      complete: (response) => {
        this.renderWith(response.responseText);
      },
    });
  }

  destroy() {
    $.ajax({
      method: 'DELETE',
      url: `/notification_triggers/${this.modelId}`,
      dataType: 'html',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      complete: (response) => {
        this.renderWith(response.responseText);
      },
    });
  }

  update() {
    $.ajax({
      method: 'PUT',
      url: `/notification_triggers/${this.modelId}`,
      data: this.formData,
      dataType: 'html',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
    });
  }

  renderWith(html) {
    this.$element.replaceWith(html);
  }

  get formData() {
    return Object.fromEntries(new FormData(this.formTarget));
  }

  get $element() {
    return $(this.element);
  }

  get modelId() {
    return this.$element.data('id');
  }
}
