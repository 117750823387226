import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'spaceNeededSelect',
    'projectTypeSelect',
    'template',
    'haveSamplesSelect',
    'fixtureHelpSelect',
    'fixtureMaterialsSelect',
  ];

  initialize() {
    window.console.log('CX Form Controller Initialized!');
  }

  connect() {
    if (this.hasSpaceNeededSelectTarget) {
      this.updateSpaceNeeded();
    }
    if (this.hasProjectTypeSelectTarget) {
      this.updateProjectType();
    }
    if (this.hasHaveSamplesSelectTarget) {
      this.updateHaveSamples();
    }
    if (this.hasFixtureHelpSelectTarget) {
      this.updateFixtureHelp();
    }
    if (this.hasFixtureMaterialsSelectTarget) {
      this.updateFixtureMaterials();
    }
  }

  updateSpaceNeeded() {
    const spaceNeededSelect = this.spaceNeededSelectTarget.value;

    if (spaceNeededSelect === 'Husky Shop') {
      $('#everbilt_subspaces').hide();
      $('#everbilt_subspaces').find(':checkbox').prop('checked', false);
      $('#subspaces').show();
      $('#space_drawings').show();
      $('#husky_subspaces').show();
    } else if (spaceNeededSelect === 'Everbilt Workshop') {
      $('#husky_subspaces').hide();
      $('#husky_subspaces').find(':checkbox').prop('checked', false);
      $('#subspaces').show();
      $('#space_drawings').show();
      $('#everbilt_subspaces').show();
    } else {
      $('#subspaces').hide();
      $('#space_drawings').hide();
      $('#everbilt_subspaces').find(':checkbox').prop('checked', false);
      $('#husky_subspaces').find(':checkbox').prop('checked', false);
    }
  }

  updateProjectType() {
    const projectTypeSelect = this.projectTypeSelectTarget.value;

    if (projectTypeSelect === 'Other') {
      $('#otherProjectType').show();
    } else {
      $('#otherProjectType').hide();
    }
  }

  updateHaveSamples() {
    const haveSamplesSelect = this.haveSamplesSelectTarget.value;

    if (haveSamplesSelect === 'No, but I have already created a Sample Request in LIMSey or SRM') {
      $('#srm_request_id').show();
    } else {
      $('#srm_request_id').hide();
    }
  }

  updateFixtureHelp() {
    const fixtureHelpSelect = this.fixtureHelpSelectTarget.value;

    if (fixtureHelpSelect === 'Yes') {
      $('#fixture_details').show();
      $('#have_fixture_materials').show();
      $('#fixture_disposition').show();
    } else {
      $('#fixture_details').hide();
      $('#have_fixture_materials').hide();
      $('#fixture_materials_location').hide();
      $('#fixture_disposition').hide();
    }
  }

  updateFixtureMaterials() {
    const fixtureMaterialsSelect = this.fixtureMaterialsSelectTarget.value;

    if (fixtureMaterialsSelect === 'Yes') {
      $('#fixture_materials_location').show();
    } else {
      $('#fixture_materials_location').hide();
    }
  }

  removeAttendee(event) {
    $(event.target).closest('.relative').find('.destroy_hidden_field').val('1');
    $(event.target).closest('.attendee_row').hide();
  }

  addAttendee() {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    $('.attendee_rows').append(content);
  }
}
