import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'input',
    'item',
    'link',
  ]

  connect() {
    this.assignAttributes();
  }

  assignAttributes() {
    this.url = this.data.get('url');
    this.name = this.data.get('name');
    this.id = this.data.get('id');
  }

  update() {
    fetch(`${this.url}/${this.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ saved_search_param: { name: this.inputTarget.innerText } }),
    })
      .then(response => response.json())
      .then((data) => {
        if (data.status === 'deleted') {
          this.itemTarget.remove();
          window.toastr.success('Saved Search deleted');
        } else if (data.status === 'error') {
          throw new Error(this.decorate(data.errors));
        } else {
          this.linkTarget.innerHTML = data.link;
          this.element.dataset.status = 'active';
          window.toastr.success('Saved Search updated');
        }
      })
      .catch((error_messages) => {
        window.toastr.error(`Search was not updated ${error_messages}`);
      });
  }

  create() {
    const newData = {
      name: this.inputTarget.innerText,
      page_name: this.data.get('page-name'),
      params: this.data.get('params'),
    };

    fetch(this.url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ saved_search_param: newData }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 'error') {
          throw new Error(this.decorate(data.errors));
        } else {
          window.dispatchEvent(new CustomEvent('saved_search_created'));
          window.toastr.success('Search Saved');
          this.itemTarget.remove();
        }
      })
      .catch((error_messages) => {
        window.toastr.error(`Search wasn't saved ${error_messages}`);
      });
  }

  destroy() {
    fetch(`${this.url}/${this.id}`, { method: 'DELETE' })
      .then((response) => {
        if (response.ok) {
          this.itemTarget.remove();
          window.toastr.success('Saved Search Deleted');
        }
      });
  }

  new() {
    this.element.dataset.status = 'creating';
  }

  edit() {
    this.element.dataset.status = 'editing';
  }

  undo() {
    this.element.dataset.status = 'active';
  }

  decorate(errors) {
    return Object.entries(errors)
      .map(([field, errorMessages]) => `${field}: ${errorMessages.join(', ')}`)
      .join('\n');
  }
}
