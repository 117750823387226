import { Controller } from 'stimulus';

// this controller allows you to check for changes on a given event
// to use: set the inputs that you'd like to scan for changes as the 'input' targets
//
// trigger a change check via the 'check' method
//   ie: data-action="turbolinks:before-visit@document->change-checker#check"
// Note: the check method will cancel the event if the check returns false
//  (in the case above, this will prevent the navigation from continuing)
//
// * if you'd like to pass the location / remote value of the original link
//   that was interrupted to a new link (ie: a modal, simply set the 'link' target
//
// * if you'd like to skip the comparison check for a given link
//   set the `data-skip="true"` attribute on that link
// * if you'd like to skip comparison checks when a link is clicked going forward
//   (ie: a submit button), use the 'clean' method ie: data-action="click->checker#clean"
//
export default class extends Controller {
  static targets = ['input', 'link'];

  initialize() {
    this.refresh();
  }

  refresh() {
    this.origValues = this._getValues();
    this.unclean();
  }

  unclean() {
    this._clean = false;
  }

  clean() {
    this._clean = true;
  }

  proceed(e) {
    this.clean();

    const link = this.linkTarget;
    const data = link.dataset;
    const { remote } = data;

    if (!remote || remote === 'undefined') {
      Turbolinks.visit(e.target.href, { action: 'replace' });
    } else {
      $.ajax({
        method: data['http-method'] || 'GET',
        url: link.href,
        dataType: 'script',
      });
    }
  }

  _skipCheck(link) {
    return (link.dataset.skip === 'true') || this._clean;
  }

  check(e) {
    const link = this._getLink(e);

    if (this._skipCheck(link) || (this.origValues === this._getValues())) {
      this._skip = false;
      return;
    }
    // prevent navigation if bound to nav events
    e.preventDefault();
    e.stopPropagation();
    this.element.dispatchEvent(new Event('checker:changed'));
    this._setLink(link);
  }

  _getLink(e) {
    let link = e.target;
    if (!link.tagName) link = link.activeElement;

    return link;
  }

  // set modal link if it exists
  _setLink(link) {
    if (!this.hasLinkTarget) return;

    this.linkTarget.dataset.remote = link.dataset.remote;
    this.linkTarget.href = link.href;
  }

  _getValues() {
    return JSON.stringify(this.inputTargets.map(el => el.value));
  }
}
