import { Controller } from 'stimulus';

require('blueimp-file-upload');

export default class extends Controller {
  initialize() {
    window.console.log('AttachmentUploaderController Initialized!');
  }

  connect() {
    const $this = this;

    $('.attachment_upload').fileupload({
      sequentialUploads: true,
      dropZone: $('#attachment_drop_zone, #folder_breadcrumbs .attachment_folder'),
      drop(event, data) {
        const target = $(event.delegatedEvent.target);
        $this.attachmentCount = data.files.length;

        const attachableData = target.closest('.attachable').data();
        if (typeof attachableData === 'undefined') { return; } // If this is the main dropzone for the folder
        this.dropFolderId = attachableData.id;
      },
      change(event, data) {
        $this.attachmentCount = data.files.length;
      },
      add(event, data) {
        let error = null;
        const file = data.files[0];

        if (file.size > (5000 * 1024 * 1024)) {
          // check for allowed file size (500 MB)
          error = `${file.name} is too large (max is 500 MB)`;
        }

        $('#filename').html('');

        data.progressBar = $(`
          <span class="text-sm">
            ${file.name}
          </span>
          <div class="mb-2">
            <div class="progress-bar bg-blue-400 text-xs leading-none py-1 pl-5 text-white shadow w-full bg-grey-light">
              0%
            </div>
          </div>`).appendTo('#progress-bar-container');

        if (!error) {
          const options = {
            filename: file.name,
            _: Date.now(),
          };

          $.getJSON('/presign', options, (result) => {
            // This writes to the cache folder.
            data.formData = result.fields;
            data.url = result.url;
            data.paramName = 'file';
            data.submit();
          });
        } else {
          data.progressBar.find('.progress-bar').html(`<span class="error">${error}</span>`);
          $this.attachmentCount -= 1; // Remove errors from the total count of uploaded files.
        }
      }, // fileUpload add()

      progress(e, data) {
        const progress = parseInt(data.loaded / data.total * 100, 10);
        const percentage = `${progress.toString()}%`;
        data.progressBar.find('div').css('width', percentage).html(percentage);
      },

      done(e, data) {
        data.progressBar.remove();

        const attachment = {
          id: data.formData.key.match(/cache\/(.+)/)[1], // we have to remove the prefix part
          storage: 'cache',
          metadata: {
            size: data.files[0].size,
            filename: data.files[0].name.match(/[^/\\]+$/)[0], // IE return full path
            mime_type: data.files[0].type,
          },
        };

        const form = $(this).closest('form');
        const formData = new FormData(form[0]);
        formData.append($(this).attr('name'), JSON.stringify(attachment));

        if ($this.isBatchUpload && $this.batchName) {
          $this.batchCounter += 1;

          formData.append('attachment[name]', `${$this.batchName} ${$this.batchCounter}`);
        } else {
          formData.append('attachment[name]', attachment.metadata.filename);
        }

        if (this.dropFolderId) {
          // If file/s have been droped into a folder.
          formData.append('attachment[attachment_folder_id]', this.dropFolderId);
          this.dropFolderId = null;
        }

        $this.createAttachment(form, formData);
      }, // fileUpload done()
    }); // fileUpload
  } // connect()

  createAttachment(form, formData) {
    const $this = this;
    // Submit to rails server via ajax
    $.ajax(form.attr('action'), {
      contentType: false,
      processData: false,
      data: formData,
      method: form.attr('method'),
      dataType: 'script',
      success: () => {
        const newIdElements = $('.new_attachment_id'); // These elements are added by attachments/create.html.erb

        if (newIdElements.length === $this.attachmentCount) {
          // Elements are added as they are saved. Once the element count equals the number of
          // uploaded files (minus errors), proceed.
          const newIds = $.makeArray(newIdElements).map(element => $(element).data('id')).sort();

          newIdElements.detach(); // remove added elements for the next upload.

          $.ajax({
            method: 'GET',
            url: `/attachments/${newIds[0]}/edit`,
            dataType: 'script',
            contentType: false,
            success: () => {
              const oldName = $('#attachment_name').val();

              $(`#edit_attachment_${newIds[0]}`).submit((event) => {
                if (newIds.length > 1) { // if this is a batch upload.
                  // This form is returned by POST /attachments.js
                  const updateForm = $(event.target)[0];
                  const updateFormData = new FormData(updateForm);
                  const newName = updateFormData.get('attachment[name]');

                  const sequenceNames = newName !== oldName;
                  window.setTimeout(() => { // This 1/4 second timeout prevents a race condition.
                    for (let i = 0; i < newIds.length; i += 1) {
                      if (sequenceNames) { // Only update name if a new on is provided.
                        updateFormData.set('attachment[name]', `${newName} ${i + 1}`);
                      } else {
                        // If no new name is provided, simply delete this attribute
                        // from the data sent to PATCH /attachments/:id
                        updateFormData.delete('attachment[name]');
                      }

                      $.ajax({ // Resubmit each Attachment with updated attributes.
                        method: 'PUT',
                        url: `/attachments/${newIds[i]}`,
                        data: updateFormData,
                        contentType: false,
                        processData: false,
                        dataType: 'script',
                      });
                    }
                  }, 250);
                }
              });
            },
          });
        }
      },
    });
  }
}
