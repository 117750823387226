import { Controller } from 'stimulus';

// customize collapse elements
export default class extends Controller {
  _link() {
    const link = $('<a>', { href: '#', text: '...', class: 'collapse-toggle' });
    link.click((event) => {
      event.preventDefault();

      this._toggle();
    });

    return link;
  }

  _setCollapse() {
    const link = this._link();

    $(this.element).append(link);
    this._toggle();
  }

  _toggle() {
    let $elements = $(this.element).children().not('.collapse-toggle').toArray();
    $elements = $elements.slice(this.maxLength, $elements.length);

    $elements.forEach((el) => {
      $(el).toggleClass('hidden');
    });
  }

  initialize() {
    this.maxLength = parseInt(this.element.dataset.size || 3, 10);
    this.total = this.element.childElementCount;

    if (this.element.childElementCount <= this.maxLength) return;

    this._setCollapse();
  }
}
