import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  filter(event) {
    const { key } = event;
    const $input = $(event.target);

    if (key.length > 1) { return; } // special characters like Backspace

    event.preventDefault();

    switch (this.locale) {
      case 'en':
        this.filterUSNumber($input, key);
    }
  }

  filterUSNumber($input, key) {
    const val = `${$input.val()}${key}`;
    const { length } = val;

    if (val.match(/^[2-9][0-9]?[0-9]?-?[2-9]?[0-9]?[0-9]?-?[0-9]?[0-9]?[0-9]?[0-9]?$/)) {
      $input.val(val);
    }

    if (length === 3 || length === 7) {
      $input.val(`${val}-`);
    }
  }

  get locale() {
    return this.$element.data('locale');
  }

  get $element() {
    return $(this.element);
  }
}
