import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    if (window.location.hash) {
      document.querySelector(window.location.hash).scrollIntoView(
        {
          behavior: 'auto',
          block: 'center',
          inline: 'nearest',
        },
      );

      this.element.scrollIntoView(
        {
          behavior: 'auto',
          block: 'end',
          inline: 'nearest',
        },
      );
    }
  }
}
