import { Controller } from 'stimulus';

export default class extends Controller {
  reset() {
    this.inputTargets().forEach((el) => {
      if (el.dataset.disabled === 'true') {
        el.removeAttribute('disabled');
        el.dataset.disabled = null;
      }
      el.dataset.dirty = null;
    });
  }

  connect() {
    this.reset();
  }

  prepare() {
    this.rowTargets.forEach((row) => {
      const inputs = this.inputTargets(row);
      let isDirty = false;
      inputs.forEach((el) => {
        if (el.dataset.dirty !== 'true') {
          el.setAttribute('disabled', 'disabled');
          el.dataset.disabled = 'true';
        } else {
          isDirty = true;
        }
      });

      if (isDirty === true) {
        $(row).find('.dirty-by-association').prop('disabled', false);
      }
    });
    this._cleanupAttachments();
    this.element.dispatchEvent(new Event('dirty:prepared'));
  }

  // remove attachments after first submission
  _cleanupAttachments() {
    let submitButton = false;
    Array.from(this.element.children).forEach((el) => {
      if (submitButton) {
        if (el.dataset.pending === 'yes') {
          el.remove();
        } else {
          el.dataset.pending = 'yes';
        }
      }
      if (el.type === 'submit') submitButton = true;
    });
  }

  get rowTargets() {
    return Array.from(this.element.querySelectorAll('[data-dirty-check-target="row"]'));
  }

  inputTargets(base = this.element) {
    return Array.from(base.querySelectorAll('[data-dirty-check-target="input"]'));
  }

  change(e) {
    const el = e.target;
    el.dataset.dirty = 'true';
    $(el).addClass('border-3 border-orange-300');
  }
}
