import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'control']

  _setupDatePicker() {
    $(this._triggerElement()).datepicker({
      orientation: 'left',
      autoclose: true,
      format: 'yyyy-mm-dd',
      todayBtn: 'linked',
      todayHighlight: true,
    });
  }

  _triggerElement() {
    if (this.hasControlTarget) return this.controlTarget;

    return this.element;
  }

  _bindInput() {
    if (!this.hasInputTarget) return;

    $(this._triggerElement()).on('changeDate', this._setInput.bind(this));
  }

  _setInput(event) {
    const { date } = event;

    this.inputTarget.value = date.toISOString();
    this.inputTarget.dispatchEvent(new Event('change'));
  }

  initialize() {
    this._setupDatePicker();
    this._bindInput();
  }
}
