import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    window.console.log('CorrectiveAction Controller initialized!');
  }

  complete(event) {
    const toggle = $(event.target);

    if (toggle.hasClass('disabled')) { return; }

    const section = toggle.closest('[name=status]');
    const status = section.data('status');
    const complete = this.data.get(`${status.replace('_', '-')}-complete`) === 'true';

    const data = {};
    data[`${status}_complete`] = !complete;

    $.ajax({
      method: 'PUT',
      url: `/corrective_actions/${this.correctiveActionId}`,
      data: { corrective_action: data },
      dataType: 'script',
      beforeSend: () => {
        toggle.addClass('disabled');
      },
      complete: () => {
        this.data.set(`${status.replace('_', '-')}-complete`, !complete);

        const grayButtonClass = 'bg-gray-100 text-gray-700 focus:border-gray-300 focus:shadow-outline-gray hover:bg-gray-50 active:bg-gray-200 active:text-gray-800';
        const greenButtonClass = 'bg-green-100 text-green-700 focus:border-green-300 focus:shadow-outline-green hover:bg-green-50 active:bg-green-200 active:text-green-800';

        toggle.removeClass('disabled');

        if (complete) {
          toggle.removeClass(greenButtonClass);
          toggle.addClass(grayButtonClass).text('Incomplete');
        } else {
          toggle.removeClass(grayButtonClass);
          toggle.addClass(greenButtonClass).text('Completed');
        }
      },
    });
  }

  get correctiveActionId() {
    return this.data.get('id');
  }
}
