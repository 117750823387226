import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'element', 'mirror'];

  // Iterate over all element targets and add the disabled attribute to
  // disable them.
  _hideElements() {
    this.elementTargets.forEach((element) => {
      element.classList.add('hidden');
    });
  }

  // Iterate over all element targets and remove the disabled attribute to
  // enable them.
  _showElements() {
    this.elementTargets.forEach((element) => {
      element.classList.remove('hidden');
    });
  }

  // Determine whether or not a given checkbox element has a value entered.
  //
  // @param [Element] checkboxElement the element to check the value of.
  //
  // @return [Boolean] true if the checkbox element has a value, otherwise false.
  _checkboxIsChecked(checkboxElement) {
    return checkboxElement.checked;
  }

  // Iterate through all checkboxes to determine if values are present for each one.
  // If all checkboxes have a value set, enable the element targets.
  // Otherwise, disable the element targets.
  checkCheckboxes() {
    this._setMirror();
    if (this.checkboxTargets.some(this._checkboxIsChecked) && this.checkboxTargets.length > 0) {
      this._showElements();
    } else {
      this._hideElements();
    }
  }

  _setMirror() {
    if (!this.hasMirrorTarget) return;
    this.mirrorTarget.innerHTML = '';

    this.checkboxTargets.forEach((el) => {
      if (this._checkboxIsChecked(el)) {
        const opt = document.createElement('option');
        opt.setAttribute('selected', 'selected');
        opt.setAttribute('value', el.name);
        opt.innerHTML = el.name;
        this.mirrorTarget.appendChild(opt);
      }
    });
  }
}
