import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  initialize() {
    window.console.log('Sortable Controller Initialized!');
  }

  connect() {
    $(this.element).sortable({
      handle: '[name=sort_handle]',
      tolerance: 'pointer',
      items: '> [data-target="sortable.item"]',
      cursor: 'move',
      stop: () => {
        $.each(this.itemTargets, (i, item) => {
          const { url, model } = $(item).data();

          const data = {};
          data[model] = { position: i };

          $.ajax({
            method: 'PATCH',
            url: `${url}?do_not_update_ui=true`,
            data,
            dataType: 'script',
            headers: { 'X-CSRF-Token': Rails.csrfToken() },
          });
        });
      },
    });
  }
}
