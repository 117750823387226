import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['clone', 'container'];

  connect() {
    this.attribute = this.element.dataset.attribute;
    this.substitutable = this.element.dataset.substitutable;
    this.finder = this.element.dataset.finder;
    this.count = parseInt(this.substitutable, 10);
    if (this.hasCloneTarget) this.cloneable = this.cloneTarget.cloneNode(true);
    if (this.element.dataset.noBlank === 'true') {
      this.cloneTarget.remove();
    } else {
      this.count += 1;
    }
  }

  _updateChildren(node) {
    const children = Array.from(node.children);
    if (children.length === 0) return;

    children.forEach((child) => {
      this._updateAttribute(child);
      this._updateChildren(child);
    });
  }

  handleClick(e) {
    e.preventDefault();

    const cloned = this.cloneable.cloneNode(true);
    cloned.classList.add('cloned');
    this._updateAttribute(cloned);
    this._updateChildren(cloned);
    this.count += 1;

    this.containerTarget.appendChild(cloned);
  }

  _updateAttribute(node) {
    const origValue = node.getAttribute(this.attribute);
    if (!origValue) return;

    let replacement;
    let replaced;

    if (this.finder) {
      replacement = this.finder.replace('REPLACE', this.count);
      replaced = this.finder.replace('REPLACE', this.substitutable);
    } else {
      replacement = this.count;
      replaced = this.substitutable;
    }

    node.setAttribute(this.attribute, origValue.replace(replaced, replacement));
  }
}
