import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['updateButton'];

  initialize() {
    window.console.log('SamplePlanner Controller initialized!');
  }

  connect() {
    this.updates = [];
  }

  addToUpdate(event) {
    this.updates.push($(event.target));
  }

  update(event) {
    if (this.updates.length === 0) { return; }
    const theseUpdates = this.updates;
    this.updates = []; // Reset

    const $updateButton = $(event.target);
    const updateButtonContent = $updateButton.html();

    $updateButton.prop('disabled', true);
    $updateButton.html($updateButton.data('disable-with'));

    for (let i = 0; i < theseUpdates.length; i += 1) {
      const $element = theseUpdates[i];

      $.ajax({
        method: 'PUT',
        url: `/tasks/${$element.data('taskId')}/planner_update`,
        data: {
          results_labels: $element.val(),
          sample_id: $element.data('sampleId'),
        },
        headers: { 'X-CSRF-Token': Rails.csrfToken() },
        dataType: 'script',
        complete: () => {
          if (i === theseUpdates.length - 1) {
            $updateButton.prop('disabled', false);
            $updateButton.html(updateButtonContent);
            window.toastr.success('Result Labels successfully updated.');
          }
        },
      });
    }
  }
}
