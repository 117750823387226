import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['spinner']

  initialize() {
    const spinner = this.spinnerTarget;

    const { reportId } = spinner.dataset;
    const { klass } = spinner.dataset;

    // const timer = 'timer';

    // this[timer+'_klass_'+klass+'_report_id_'+report_id] = setInterval( function(){
    const myInterval = setInterval(() => $.ajax({
      type: 'POST',
      url: `/report_statuses?${klass}_id=${reportId}`,
      cache: true,
      dataType: 'script',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      success() {
        clearInterval(myInterval);
        // console.log(this.spinnerTarget)
        // console.log(klass)
      },
    }), 3000);
  }
}
