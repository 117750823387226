import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fieldType', 'showConditions', 'filterType', 'form'];

  initialize() {
    window.console.log('SampleResultFieldForm Controller Initialized!');
  }

  connect() {
    this.hideOptionalFields(0);
    this.updateForm(0);
    this.updateConditions(0);
  }

  changeFieldType() {
    this.hideOptionalFields(200);
    this.updateForm(200);
  }

  updateForm(speed) {
    $('[name=filter_type]').hide(speed);

    switch (this.fieldType) {
      case 'number':
        $('[name=conditions]').show(speed);
        break;
      case 'dropdown':
        $('[name=dropdown_options]').show(speed);
        $('[name=conditions]').show(speed);
        break;
      case 'formula':
        $('[name=formula]').show(speed);
        $('[name=conditions]').show(speed);
        break;
      case 'static':
        $('[name=static_content]').show(speed);
        break;
      default:
        break;
    }
  }

  updateConditions(speed) {
    this.hideConditionalFields(speed);
    $('[name=filter_type]').hide(speed);

    if (this.showConditions) {
      switch (this.fieldType) {
        case 'number':
          $('[name=filter_type]').show(speed);
          this.updateFilter(speed);
          break;
        case 'formula':
          $('[name=filter_type]').show(speed);
          this.updateFilter(speed);
          break;
        case 'dropdown':
          $('[name=dropdown_pass]').show(speed);
          $('[name=dropdown_fail]').show(speed);
          break;
        default:
          break;
      }
    }
  }

  updateFilter(speed) {
    this.hideConditionalFields(speed);

    if (this.showConditions) {
      switch (this.filterType) {
        case 'maximum':
          $('[name=maximum]').show(speed);
          break;
        case 'minimum':
          $('[name=minimum]').show(speed);
          break;
        case 'between':
          $('[name=value_one]').show(speed).find('input').attr('placeholder', 'The LOWER value, less than will be considered FAIL');
          $('[name=value_two]').show(speed).find('input').attr('placeholder', 'The HIGHER value, greater than will be considered FAIL');
          break;
        case 'outside':
          $('[name=value_one]').show(speed).find('input').attr('placeholder', 'The LOWER value, greater than will be considered FAIL');
          $('[name=value_two]').show(speed).find('input').attr('placeholder', 'The HIGHER value, less than will be considered FAIL');
          break;
        default:
          break;
      }
    }
  }

  hideConditionalFields(speed) {
    $('[name=minimum]').hide(speed);
    $('[name=maximum]').hide(speed);
    $('[name=value_one]').hide(speed);
    $('[name=value_two]').hide(speed);
    $('[name=dropdown_pass]').hide(speed);
    $('[name=dropdown_fail]').hide(speed);
    $('[name=static_content]').hide(speed);
  }

  hideTypeFields(speed) {
    $('[name=formula]').hide();
    $('[name=dropdown_options]').hide(speed);
  }

  hideOptionalFields(speed) {
    this.hideConditionalFields(speed);
    this.hideTypeFields(speed);
    this.hideConditions(speed);
  }

  hideConditions(speed) {
    $('[name=conditions]').hide(speed);
  }

  cleanForm() {
    this.$form.find('.input-container input[type=text]:hidden').val('');
    this.$form.find('.input-container input[type=number]:hidden').val('');
    this.$form.find('.input-container input[type=radio]:hidden').val(false);
    this.$form.find('.input-container input[type=checkbox]:hidden').prop('checked', false);
    this.$form.find('.input-container text_area:hidden').val('');
    this.$form.find('.input-container select:hidden').val('');
  }

  get $form() {
    return $(this.formTarget);
  }

  get fieldType() {
    return this.fieldTypeTarget.value;
  }

  get filterType() {
    return this.filterTypeTarget.value;
  }

  get showConditions() {
    if (this.hasShowConditionsTarget) return this.showConditionsTarget.checked;

    return false;
  }

  set showConditions(show) {
    this.showConditionsTarget.checked = show;
    $('#sample_result_field_conditions_false').prop('checked', show);
  }
}
