import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'match']

  connect() {
    this.change();
  }

  change() {
    this.matchTargets.forEach((target) => {
      if (target.dataset.value === this.selectTarget.value) {
        target.classList.remove('invisible');
        target.querySelectorAll('input,select').forEach((input) => {
          input.removeAttribute('disabled');
        });
      } else {
        target.classList.add('invisible');
        target.querySelectorAll('input,select').forEach((input) => {
          input.setAttribute('disabled', 'disabled');
        });
      }
    });
  }
}
