import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['query'];

  initialize() {
    window.console.log('GlobalSearch Controller Initiated!');

    this.enableClear = true;
    $('body').click(() => {
      if (this.enableClear) {
        this.clear();
      }
    });
  }

  search() {
    if (this.query === '') {
      this.clear();
      return;
    }

    $.ajax({
      method: 'POST',
      url: '/global_searches',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      data: {
        global_search: {
          query: this.query,
        },
      },
      dataType: 'json',
      success: (response) => {
        const data = this.parseGlobalSearchResponse(response);
        this.renderGlobalSearchResults(data);
      },
    });
  }

  renderGlobalSearchResults(data) {
    this.clear();
    this.renderJobResults(data.jobs);
    this.renderDocumentResults(data.documents);
    this.renderCorrectiveActionResults(data.correctiveActions);
    this.renderEquipmentResults(data.equipment);
  }

  renderEquipmentResults(equipmentData) {
    if (Object.entries(equipmentData).length === 0) { return; } // If there are no results.

    const html = $('<div class="bg-white shadow-xl rounded-lg px-4 py-3 overflow-y-auto"><div class="text-lg font-medium text-gray-700">Equipment</div></div>');

    Object.keys(equipmentData).forEach((key) => {
      html.append(`<div class="ml-4 font-medium text-gray-600 my-1">${key}</div>`); // status
      $.each(equipmentData[key], function appendEquipmentData() {
        html.append(`<div class="result ml-8 text-gray-400 hover:text-blue-500"><a href="${this.url}">${this.other_id} - ${this.name}</a></div>`);
      });
    });

    $('#global_search_results').append(html);
  }

  renderJobResults(jobData) {
    if (Object.entries(jobData).length === 0) { return; } // If there are no results.

    const html = $('<div class="bg-white shadow-xl rounded-lg px-4 py-3 overflow-y-auto"><div class="text-lg font-medium text-gray-700">Jobs</div></div>');

    Object.keys(jobData).forEach((key) => {
      html.append(`<div class="ml-4 font-medium text-gray-600 my-1">${key}</div>`); // status
      $.each(jobData[key], function appendResult() {
        html.append(`<div class="result ml-8 text-gray-400 hover:text-blue-500"><a href="${this.url}">${this.id} - ${this.name}</a></div>`);
      });
    });

    $('#global_search_results').append(html);
  }

  renderDocumentResults(documentData) {
    if (Object.entries(documentData).length === 0) { return; } // If there are no results.

    const html = $('<div class="bg-white shadow-xl rounded-lg px-4 py-3 overflow-y-auto"><div class="text-lg font-medium text-gray-700">Documents</div></div>');

    Object.keys(documentData).forEach((key) => {
      html.append(`<div class="ml-4 font-medium text-gray-600 my-1 capitalize">${key}</div>`); // status
      $.each(documentData[key], (i, document) => {
        const title = this.truncate(document.title, 77);

        html.append(
          `<div class="result ml-8 text-gray-400 hover:text-blue-500"><a href="${document.url}">${document.name} - ${title}</a></div>`,
        );
      });
    });

    $('#global_search_results').append(html);
  }

  renderCorrectiveActionResults(correctiveActionData) {
    if (Object.entries(correctiveActionData).length === 0) { return; } // If there are no results.

    const html = $('<div class="bg-white shadow-xl rounded-lg px-4 py-3 overflow-y-auto"><div class="text-lg font-medium text-gray-700">Corrective Actions</div></div>');

    Object.keys(correctiveActionData).forEach((key) => {
      html.append(`<div class="ml-4 font-medium text-gray-600 my-1 capitalize">${key}</div>`); // status
      $.each(correctiveActionData[key], (i, correctiveAction) => {
        const description = this.truncate(correctiveAction.description, 77);
        html.append(
          `<div class="result ml-8 text-gray-400 hover:text-blue-500"><a href="${correctiveAction.url}">CAR-${correctiveAction.car_id} - ${description}</a></div>`,
        );
      });
    });

    $('#global_search_results').append(html);
  }

  parseGlobalSearchResponse(response) {
    return {
      jobs: this.parseByStatus(response.jobs),
      documents: this.parseByStatus(response.documents),
      correctiveActions: this.parseByStatus(response.corrective_actions),
      equipment: this.parseByStatus(response.equipment),
    };
  }

  parseByStatus(data) {
    const results = {};

    $.each(data, function parse() {
      const { status } = this;

      if (!(status in data) && (typeof results[status] === 'undefined')) {
        results[status] = [];
      }

      results[status].push(this);
    });

    return results;
  }

  truncate(string, length) {
    if (string.length > length) {
      return `${string.substring(0, length)}...`;
    }

    return string;
  }

  doClear() {
    this.enableClear = true;
  }

  dontClear() {
    this.enableClear = false;
  }

  clear() {
    $('#global_search_results').empty();
  }

  get query() {
    return this.queryTarget.value;
  }
}
