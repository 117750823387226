import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    window.console.log('CalibrationRecordereController Initialized!');
    if (this.hasLogEntryForm()) {
      this.adjustForms();
    }
  }

  adjustForms() {
    this.logEntryForm.find('[type=submit]').hide();
  }

  submit(event) {
    event.preventDefault();
  }

  hasLogEntryForm() {
    return this.$logEntry.length !== 0;
  }

  get $element() {
    return $(this.element);
  }

  get $logEntry() {
    return this.$element.find('[name=log_entry]');
  }

  get logEntryForm() {
    return this.$logEntry.find('form');
  }

  get $calibration() {
    return this.$element.find('[name=calibration]');
  }

  get calibrationForm() {
    return this.$calibration.find('form');
  }
}
