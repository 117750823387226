import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu', 'arrow', 'item'];

  connect() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.closeMenu();
  }

  disconnect() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.element.contains(event.target)) {
      return;
    }

    this.closeMenu();
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.itemTarget.classList.toggle('active', this.isOpen);
  }

  closeMenu() {
    this.isOpen = false;
    this.itemTarget.classList.remove('active');
  }
}
