import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    console.log('forge calendar initialized!');
  }

  toggleDay(event) {
    const cell = $(event.target);
    const forge_request_id = cell.data('forge-request-id');
    const forge_aisle_bay_id = cell.data('forge-aisle-bay-id');
    const date = cell.data('date');

    $.ajax({
      method: 'POST',
      url: `/forge_requests/${forge_request_id}/forge_calendar_toggles`,
      data: {
        forge_aisle_bay_id,
        date,
      },
      dataType: 'json',
      success(data) {
        switch (data.response) {
          case true:
            cell.addClass('bg-blue-200');
            break;
          case false:
            // do nothing, record can't be added
            break;
          default:
            cell.removeClass('bg-blue-200');
        }
      },
    });
  }
}
