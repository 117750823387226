import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    const $el = window.$(this.element);
    $el.select2({
      ajax: {
        url: this.element.dataset.url,
        dataType: 'json',
        processResults(data) {
          return {
            results: data,
          };
        },
      },
      placeholder: this.element.dataset.placeholder,
    });

    $el.on('select2:select select2:unselect', (_event) => {
      // Stimulus controllers do not listen for select2 events, so raise a parallel event.
      this.element.dispatchEvent(new Event('change'));
    });
  }
}
