import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['multipliers', 'product'];

  // all_children = $('*[data-target="dynamic-select.children"]').html();

  connect() {
    console.log('Multiply calculation initialized');
  }

  calcProduct() {
    var finalProduct = 1

    $(this.multipliersTargets).each(function(){
      console.log(finalProduct);
      finalProduct = finalProduct * Number($(this).val());
    })

    const product = $(this.productTarget).val(finalProduct.toFixed(2));
    product[0].dispatchEvent(new Event('change', { 'bubbles': true }));
  }
}
