// Include a filter-with data attribute on your "element" targets, add to a text
// field (or any field with a value) an action that calls #filterElements.

import { Controller } from 'stimulus';

export default class extends Controller {

  change() {
    const target_name = $(event.currentTarget).prop('name');
    if (target_name == 'job[template_id]') {
      console.log('top selected');
      $('[name="job[job_type_id]"]').prop('checked', false);
    } else {
      console.log('bottom selected');
      $('[name="job[template_id]"]').val('');
    }
  }

}
