import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'dueDateConfigurationWrapper',
    'frequencies',
    'dueDateConfiguration',
  ];

  initialize() {
    window.console.log('MaintenanceForm Controller Initialized!');
  }

  connect() {
    this.showHideDueDateConfiguration(0);
  }

  toggleDueDateConfiguration() {
    this.showHideDueDateConfiguration(300);
  }

  showHideDueDateConfiguration(speed) {
    if (this.frequency === 'does_not_repeat') {
      $(this.dueDateConfigurationWrapperTarget).hide(speed);
    } else {
      $(this.dueDateConfigurationWrapperTarget).show(speed);
    }
  }

  get frequency() {
    return this.frequenciesTarget.value;
  }
}
