import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link']

  clickHandler(event) {
    const { target } = event;
    if (target.tagName === 'A') return;

    (this.linkTargets[0] || $(target).find('a')).click();
  }

  initialize() {
    this.element.addEventListener('click', this.clickHandler.bind(this));
    this.element.style.cursor = 'pointer';
  }
}
