import { Controller } from 'stimulus';

// customize collapse elements
export default class extends Controller {
  static targets = ['template', 'content']

  initialize() {
    this.count = parseInt(this.data.get('count'), 10);
    this.template = this.templateTarget.cloneNode();
    this.template.classList.remove('hidden');
    this.templateHTML = this.templateTarget.innerHTML;
    this.templateTarget.remove();
  }

  add(event) {
    event.preventDefault();
    const clone = this.template.cloneNode();
    clone.innerHTML = this.templateHTML.replace(/:count/g, this.count);
    this.count += 1;
    this.contentTarget.appendChild(clone);
    const turbo = new Event('turbolinks:load');
    document.dispatchEvent(turbo);
  }
}
