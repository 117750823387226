import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'match']

  connect() {
    this.change();
  }

  change() {
    this.matchTargets.forEach((target) => {
      if (target.dataset.value == $(this.checkboxTarget).is(":checked").toString()) {
        target.classList.remove('hidden');
        target.querySelectorAll('input,select').forEach((input) => {
          input.removeAttribute('disabled');
        });
      } else {
        target.classList.add('hidden');
        target.querySelectorAll('input,select').forEach((input) => {
          input.setAttribute('disabled', 'disabled');
        });
      }
    });
  }
}
