import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    let opts = {};
    if (this.element.dataset.url) {
      opts = {
        ajax: {
          url: this.element.dataset.url,
          dataType: 'json',
        },
      };
    }

    window.$(this.element).select2(opts).on('change', this.handleChange.bind(this));
    this.element.classList.add('is-select2');
  }

  handleChange() {
    // alias jquery event to native event
    this.element.dispatchEvent(new Event('jChange'));
  }
}
