import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'ToggleChannel',
        id: this.element.dataset.id,
      },
      {
        received: (data) => {
          this.toggle(data);
        },
      },
    );
  }

  toggle(data) {
    if (!(data.gid === this.element.dataset.gid)) return;

    this.element.href = data.href;
    Object.keys(data).forEach((key) => {
      const el = this.element.querySelector(`[data-sel-${key}='true']`);
      if (el) el.innerText = data[key];
    });
    this.element.classList.remove('hidden');
  }
}
