import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    console.log('Insert at cursor initialized')
  }

  insertText(event) {
    var selected = $(event.target).val();

    if (selected.length === 0){
      return;
    }

    var text = ` [${selected}] `;
    var field = this.inputTarget;

    if (document.selection) {
      var range = document.selection.createRange();

      if (!range || range.parentElement() != field) {
        field.focus();
        range = field.createTextRange();
        range.collapse(false);
      }
      range.text = text;
      range.collapse(false);
      range.select();
    } else {
      field.focus();
      var val = field.value;
      var selStart = field.selectionStart;
      var caretPos = selStart + text.length;
      field.value = val.slice(0, selStart) + text + val.slice(field.selectionEnd);
      field.setSelectionRange(caretPos, caretPos);
    }

    $(event.target).prop('selectedIndex', 0);
  }
}
