import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['up', 'down']

  initialize() {
    this.pristine = true;
  }

  dirty() {
    this.pristine = false;
  }

  keydown(event) {
    if (event.keyCode === 38) {
      // up arrow
      if (this.hasUpTarget) this._clickOn(this.upTarget, event);
    } else if (event.keyCode === 40) {
      // down arrow
      if (this.hasDownTarget) this._clickOn(this.downTarget, event);
    }
  }

  _clickOn(clickTarget, event) {
    if (!this.pristine) return;

    const { target } = event;

    if (target.dataset.mute === 'true') return;

    event.stopPropagation();
    event.preventDefault();

    Turbolinks.visit(clickTarget.href, { action: 'replace' });
  }
}
