import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link', 'element'];

  _toggleElementVisibility(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.elementTarget.classList.contains('hidden')) {
      this.elementTarget.classList.remove('hidden');
      this.linkTarget.classList.add('hidden');
    } else {
      this.elementTarget.classList.add('hidden');
      this.linkTarget.classList.remove('hidden');
    }
  }

  initialize() {
    this.linkTarget.addEventListener('click', e => this._toggleElementVisibility(e));
  }
}
