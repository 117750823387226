import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'content'];

  initialize() {
    console.log('popover initialized!');
  }

  toggle(event) {
    event.preventDefault();

    if (this.contentTarget.classList.contains('active') == false) {
      this.show();
    } else {
      this.hide(null);
    }
  }

  show() {
    console.log('show');
    this.contentTarget.classList.add('active');
  }

  hide(event) {
    if (event && (this.contentTarget.contains(event.target) || this.buttonTarget.contains(event.target))) {
        // event.preventDefault(); // I don't remeber why I did it, but i need this line to be commented
        return;
    }
    this.contentTarget.classList.remove('active');
  }
}
