import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  initialize() {
    window.console.log('Archives Controller Initialized!');
  }

  connect() {
    const t = this;

    consumer.subscriptions.create(
      {
        channel: 'ArchivesChannel',
        id: this.hasArchivesId,
        type: this.hasArchivesType,
      },
      {
        received(data) {
          switch (data.action) {
            case 'show':
              t.show(data.id);
              break;
            default:
              throw new Error('Unknown :action');
          }
        },
      },
    );
  }

  show(id) {
    $.ajax({
      method: 'GET',
      url: `/archives/${id}`,
      dataType: 'script',
    });
  }

  get hasArchivesId() {
    return this.element.dataset.hasArchivesId;
  }

  get hasArchivesType() {
    return this.element.dataset.hasArchivesType;
  }
}
