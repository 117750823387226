import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import Loader from '@elements/Loader'
import Notice from '@elements/Notice'
import useWindowResize from '@hooks/useWindowResize'
import { useBoardContext } from '../context/BoardContext'
import { ColumnProvider } from '../context/ColumnContext'
import Column from './Column'

function KanbanBoardAppContent() {
  const boardRef = useRef(null)
  const { columns, columnsData, setColumnsData, updateJobMutation, usersQuery } = useBoardContext()

  const adjustBoardHeight = useCallback(() => {
    if (boardRef.current) {
      const margin = 50
      boardRef.current.style.height = `${window.innerHeight - boardRef.current.getBoundingClientRect().top - margin}px`
    }
  }, [])

  useWindowResize(adjustBoardHeight)

  const updateJobs = (columnId, items, statusUpdate = {}) => {
    items.forEach((job, index) => {
      updateJobMutation.mutate({
        ...job,
        ...statusUpdate,
        board_order_position: index + 1
      })
    })
  }

  const reorderCard = useCallback(({
    initialColumnName, initialIndex, reorderDestinationIndex
  }) => {
    window.requestAnimationFrame(() => {
      const updatedItems = reorder({
        list: columnsData[initialColumnName],
        startIndex: initialIndex,
        finishIndex: reorderDestinationIndex
      })

      setColumnsData(prev => ({
        ...prev,
        [initialColumnName]: updatedItems
      }))

      updateJobs(initialColumnName, updatedItems)
    })
  }, [columnsData, setColumnsData])

  const moveCard = useCallback(({
    cardId, destinationColumnName, initialColumnName, initialIndex, moveDestinationIndex
  }) => {
    window.requestAnimationFrame(() => {
      const sourceColumnReorder = columnsData[initialColumnName].filter(card => card.id !== cardId)
      const newDestinationCards = [
        ...columnsData[destinationColumnName].slice(0, moveDestinationIndex),
        columnsData[initialColumnName][initialIndex],
        ...columnsData[destinationColumnName].slice(moveDestinationIndex)
      ]

      setColumnsData(prev => ({
        ...prev,
        [initialColumnName]: sourceColumnReorder,
        [destinationColumnName]: newDestinationCards
      }))

      updateJobs(destinationColumnName, newDestinationCards, {
        status: destinationColumnName,
        queryable_status: destinationColumnName
      })
    })
  }, [columnsData, setColumnsData])

  const handleDrop = useCallback(({
    source, location
  }) => {
    const dropTargets = location?.current?.dropTargets || []
    const initialTargets = location?.initial?.dropTargets || []

    if (!dropTargets.length || source.data.type !== 'card') return

    const initialColumnName = initialTargets[1]?.data.name
    const initialColumnData = columnsData[initialColumnName]

    const draggedCard = {
      cardId: source.data.cardId,
      destinationColumnName: dropTargets.slice(-1)[0].data.name,
      initialColumnName,
      initialIndex: initialColumnData.findIndex(card => card.id === source.data.cardId),
      moveDestinationIndex: null,
      sourceColumnDataLength: initialColumnData.length
    }

    if (dropTargets.length === 1) {
      if (initialColumnName === draggedCard.destinationColumnName) {
        draggedCard.reorderDestinationIndex = getReorderDestinationIndex({
          startIndex: draggedCard.index,
          indexOfTarget: draggedCard.sourceColumnDataLength - 1,
          closestEdgeOfTarget: null,
          axis: 'vertical'
        })

        reorderCard(draggedCard)

        return
      }
    } else {
      const [{ data: destinationCardRecord }, { data: { name: columnName } }] = dropTargets
      const indexOfTarget = columnsData[columnName].findIndex(card => card.id === destinationCardRecord.cardId)
      const closestEdgeOfTarget = extractClosestEdge(destinationCardRecord)

      if (initialColumnName === draggedCard.destinationColumnName) {
        draggedCard.reorderDestinationIndex = getReorderDestinationIndex({
          startIndex: draggedCard.index,
          indexOfTarget,
          closestEdgeOfTarget,
          axis: 'vertical'
        })

        reorderCard(draggedCard)

        return
      }

      draggedCard.moveDestinationIndex = closestEdgeOfTarget === 'bottom' ? indexOfTarget + 1 : indexOfTarget
    }

    moveCard(draggedCard)
  }, [columnsData, moveCard, reorderCard])

  useEffect(() => monitorForElements({ onDrop: handleDrop }), [handleDrop])

  const renderColumns = useMemo(() => {
    if (usersQuery.status === 'error') {
      return <Notice message={usersQuery.error.message} type={usersQuery.status} />
    }

    if (usersQuery.status === 'pending') {
      return <Loader />
    }

    return columns.map(columnName => (
      <ColumnProvider key={columnName} name={columnName}>
        <Column />
      </ColumnProvider>
    ))
  }, [usersQuery, columns])

  return (
    <div className="board" ref={boardRef}>
      {renderColumns}
    </div>
  )
}

export default React.memo(KanbanBoardAppContent)
