import { Controller } from 'stimulus';

export default class extends Controller {
  snooze(event) {
    const input = $(event.target);
    const maintenanceElement = input.closest('[name=maintenance]');
    const snooze = input.prop('name');
    const maintenanceId = maintenanceElement.data('maintenance-id');

    $.ajax({
      method: 'PATCH',
      url: `/account_maintenances/${maintenanceId}`,
      data: {
        maintenance: {
          snooze,
        },
      },
      dataType: 'script',
      complete() {
        const message = snooze === 'unsnooze' ? 'Maintenance Unsnoozed' : 'Maintenance Snoozed';
        window.toastr.success(message);
      },
    });
  }
}
