import { Controller } from 'stimulus';

export default class extends Controller {
  snooze(event) {
    const input = $(event.target);
    const calibrationElement = input.closest('[name=calibration]');
    const snooze = input.prop('name');
    const calibrationId = calibrationElement.data('calibration-id');

    $.ajax({
      method: 'PATCH',
      url: `/account_calibrations/${calibrationId}`,
      dataType: 'script',
      data: {
        calibration: {
          snooze,
        },
      },
      complete() {
        const message = snooze === 'unsnooze' ? 'Calibration Unsnoozed' : 'Calibration Snoozed';
        window.toastr.success(message);
      },
    });
  }

  cancel(event) {
    const input = $(event.target);
    const calibrationElement = input.closest('[name=calibration]');
    const calibrationId = calibrationElement.data('calibration-id');

    $.ajax({
      method: 'PATCH',
      url: `/account_calibrations/${calibrationId}`,
      dataType: 'script',
      data: {
        calibration: {
          sent_for: false,
        },
      },
      complete() {
        window.toastr.success('Calibration Canceled');
      },
    });
  }
}
