import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    console.log('Schedulable controller initialized');
  }

  toggleSchedulable(event) {
    const clickedButton = $(event.target);
    const schedulableClass = clickedButton.attr('data-schedulableClass');
    const schedulableId = clickedButton.attr('data-schedulableId');

    const that = this;

    $.ajax({
      method: 'POST',
      url: '/schedulables/toggle',
      data: {
        schedulable: {
          id: schedulableId,
          klass: schedulableClass,
        },
      },
      dataType: 'json',
      success(data) {
        const { schedulable } = data;
        if (schedulable) {
          that.styleSchedulable(clickedButton);
        } else {
          that.styleUnschedulable(clickedButton);
        }
      },
    });
  }

  styleSchedulable(el) {
    const classList = el.attr('class');
    el.attr('class', classList.replace(/red/g, 'green'));
    el.text('Schedulable');
  }

  styleUnschedulable(el) {
    const classList = el.attr('class');
    el.attr('class', classList.replace(/green/g, 'red'));
    el.text('Not Schedulable');
  }
}
