import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.toggleInternal();
    this.toggleReview();
  }

  toggleInternal() {
    const internalCheckbox = document.getElementsByName('document[internal]')[1];
    const organizationContainer = document.getElementsByName('organization-container')[0];
    const classificationContainer = document.getElementsByName('classification-container')[0];
    const organizationField = document.getElementsByName('document[document_organization_id]')[0];

    if (internalCheckbox.checked === true) {
      $(organizationContainer).hide(500);
      $(organizationField).prop('required', false);
      $(classificationContainer).show(500);
    } else {
      $(organizationContainer).show(500);
      $(organizationField).prop('required', true);
      $(classificationContainer).hide(500);
    }
  }

  toggleReview() {
    const reviewCheckbox = document.getElementsByName('document[periodic_review_required]')[1];
    const reviewContainer = document.getElementsByName('review-container')[0];
    const reviewQuantityField = document.getElementsByName('document[review_frequency_quantity]')[0];
    const reviewIntervalField = document.getElementsByName('document[review_frequency_interval]')[0];

    if (reviewCheckbox.checked === true) {
      $(reviewContainer).show(500);
      $(reviewQuantityField).prop('required', true);
      $(reviewIntervalField).prop('required', true);
    } else {
      $(reviewContainer).hide(500);
      $(reviewQuantityField).prop('required', false);
      $(reviewIntervalField).prop('required', false);
    }
  }
}
