import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['row', 'cell'];

  initialize() {
    window.console.log('MoveFocusWithArrowKeys Controller Initialized!');
  }

  move(event) {
    const cell = event.target;

    switch (event.key) {
      case 'ArrowRight':
        this.moveRight(cell);
        break;
      case 'ArrowLeft':
        this.moveLeft(cell);
        break;
      case 'ArrowUp':
        this.moveUp(cell);
        break;
      case 'ArrowDown':
        this.moveDown(cell);
        break;
      default:
        break;
    }
  }

  moveRight(cell) {
    let nextColIndex = this.colIndex(cell) + 1;

    if (nextColIndex >= this.colCount) {
      nextColIndex = 0;
    }

    $(this.row(cell)[nextColIndex]).focus();
  }

  moveLeft(cell) {
    let nextColIndex = this.colIndex(cell) - 1;

    if (nextColIndex === -1) {
      nextColIndex = this.colCount - 1;
    }

    $(this.row(cell)[nextColIndex]).focus();
  }

  moveUp(cell) {
    let nextRowIndex = this.rowIndex(cell) - 1;

    if (nextRowIndex === -1) {
      nextRowIndex = this.rowCount - 1;
    }

    $(this.col(cell)[nextRowIndex]).focus();
  }

  moveDown(cell) {
    let nextRowIndex = this.rowIndex(cell) + 1;

    if (nextRowIndex >= this.rowCount) {
      nextRowIndex = 0;
    }

    $(this.col(cell)[nextRowIndex]).focus();
  }

  cellIndex(cell) {
    return this.cellTargets.indexOf(cell);
  }

  rowIndex(cell) {
    return parseInt(this.cellIndex(cell) / this.cellsPerCol, 10);
  }

  colIndex(cell) {
    return this.cellIndex(cell) % this.colCount;
  }

  col(cell) {
    return this.cols[this.colIndex(cell)];
  }

  row(cell) {
    return this.rows[this.rowIndex(cell)];
  }

  get rows() {
    const rows = [];

    for (let i = 0; i < this.rowCount; i += 1) {
      const row = [];

      for (let j = 0; j < this.cellsPerCol; j += 1) {
        row.push(this.cellTargets[j + (i * this.cellsPerCol)]);
      }

      rows.push(row);
    }

    return rows;
  }

  get cols() {
    const cols = [];

    for (let i = 0; i < this.rowCount; i += 1) {
      const col = [];

      for (let j = 0; j < this.cellsPerCol; j += 1) {
        col.push(this.cellTargets[i + (j * this.cellsPerCol)]);
      }

      cols.push(col);
    }

    return cols;
  }

  get rowCount() {
    return this.rowTargets.length;
  }

  get colCount() {
    return this.cols.length;
  }

  get cellsPerCol() {
    return this.cellTargets.length / this.rowCount;
  }
}
