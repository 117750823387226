import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'button'];

  connect() {
    console.log('Add calibration records controller connected!');
  }

  update() { 
    const selectedCalibrationId = this.selectTarget.value;
    const url = new URL(this.buttonTarget.href);
    url.searchParams.set('calibration_id', selectedCalibrationId);
    this.buttonTarget.href = url.toString();
  }
}
