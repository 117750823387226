import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['watchingButton', 'unwatchedButton'];

  initialize() {
    window.console.log('WatchToggle Controller Initialized!');
  }

  connect() {
    $(this.unwatchedButtonTarget).hide();
    $(this.watchingButtonTarget).hide();
    this.toggleButton();
  }

  toggle(event) {
    event.preventDefault();
    const watchLevel = this.watching ? 'unwatched' : 'watching';

    $.ajax({
      method: 'POST',
      url: '/watches',
      data: {
        watchable_id: this.$element.data('watchableId'),
        watchable_type: this.$element.data('watchableType'),
        set_level_to: watchLevel,
      },
      dataType: 'script',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      complete: () => {
        this.toggleWatching();
        this.toggleButton();
      },
    });
  }

  toggleWatching() {
    this.watching = !this.watching;
  }

  toggleButton() {
    if (this.watching) {
      $(this.watchingButtonTarget).show();
      $(this.unwatchedButtonTarget).hide();
    } else {
      $(this.unwatchedButtonTarget).show();
      $(this.watchingButtonTarget).hide();
    }
  }

  get watching() {
    return this.$element.data('watching');
  }

  set watching(value) {
    this.$element.data('watching', value);
  }

  get $element() {
    return $(this.element);
  }
}
