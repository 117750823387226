// This only works on links that make html requests. Does not work with AJAX.

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'submit'];

  initialize() {
    window.console.log('ChangeCheckerTemp Controller Initialized!');
  }

  connect() {
    this.reset();
  }

  proceed(event) {
    this._skipCheck = true;
    Turbolinks.visit(event.data.url, { action: 'advance' });
  }

  check(event) {
    const { target } = event;

    // when submit element is clicked we want to skip this until reload
    if (this._safeElement(target)) {
      this._skipCheck = true;
    }
    if (!this.skipCheck(target)) {
      event.preventDefault();
      event.stopPropagation();

      if (window.confirm('Leave without saving?')) {
        // detach to accomodate the hack in #pendingFileUpload
        $('#progress-bar-container .progress-bar').detach();
        this.proceed(event);
      }
    }
  }

  skipCheck() {
    if (this.pendingFileUpload()) { return false; }

    return this._skipCheck || this.origValues === this.values;
  }

  _safeElement(target) {
    let safe = false;
    this.submitTargets.forEach((el) => {
      if (target === el) safe = true;
    });

    return safe;
  }

  reset() {
    this.origValues = this.values;
    this._skipCheck = false;
  }

  pendingFileUpload() {
    // TODO: Pretty hacky. It just checks for a progress bar. A more systemtic way,
    // such as updating the #add method of file_uploader_controller.js.
    if ($('#progress-bar-container .progress-bar').length > 0) {
      return true;
    }

    return false;
  }

  get values() {
    return JSON.stringify(this.inputTargets.map(el => el.value));
  }
}
