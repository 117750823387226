import { Controller } from 'stimulus';

import InputMask from 'inputmask';

export default class extends Controller {
  initialize() {
    InputMask('https://z{+}', {
      definitions: {
        z: { validator: '[-.0123456789a-zA-Z]' },
      },
    }).mask(this.element);
  }
}
