import { Controller } from 'stimulus';

export default class extends Controller {
  expand(event) {
    const { target } = event;
    if (target.tagName === 'A') return;

    if (this.state === 'closed') {
      this.open();
    } else if (this.state === 'open') {
      this.close();
    }
  }

  close(_target) {
    this.state = 'closing';

    $(this.element).find('[name=full]').hide(500, () => {
      this.state = 'closed';
    });
  }

  open(_target) {
    this.state = 'opening';

    $(this.element).find('[name=full]').show(500, () => {
      this.state = 'open';
    });
  }

  get $element() {
    return $(this.element);
  }

  get expandableId() {
    return this.data.get('id');
  }

  get state() {
    return this.data.get('state');
  }

  set state(state) {
    this.data.set('state', state);
  }
}
