import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.pbId = this.element.dataset.pbId || 'progress-bar-container';
  }

  connect() {
    this.doneFileCounter = 0;
    this.startedFileCounter = 0;

    $(this.element).fileupload({
      replaceFileInput: false,
      start: (_event) => {
        this.disableForm();
      },
      add: (_event, data) => {
        if (!this.validateFile(data.files[0])) { return; }

        this.startedFileCounter += 1;

        data.progressBar = this.$progressBar(data.files[0].name);
        data.progressBar.appendTo(`#${this.pbId}`);

        this.presignFile(data);
      },
      progress: (e, data) => {
        const progress = parseInt(data.loaded / data.total * 100, 10);
        const percentage = `${progress.toString()}%`;

        data.progressBar.find('div').css('width', percentage).html(percentage);
      },
      done: (_event, data) => {
        this.doneFileCounter += 1;

        if (this.$element.data('directUpload') === true) {
          this.uploadFile(data);
        } else {
          this.appendFileField(data);
        }
      },
      always: () => {
        if (this.doneFileCounter === this.startedFileCounter) {
          this.enableForm();
        }
      },

    });
  }

  appendFileField(fileData) {
    // To append files, the data-name attribute of this.element needs to replace
    // the ordinal for models with an uploader attribute to "fileCounter"
    // Example: task_field[attachments_attributes][fileCounter][document]
    const name = this.$element.data('name').replace('[fileCounter]', this.doneFileCounter);
    const fileField = $(`<input type="hidden" name="${name}">`);
    const attachmentData = this.parseAttachmentData(fileData);

    fileField.val(attachmentData);
    this.$form.append(fileField);
  }

  uploadFile(fileData) {
    const formData = new FormData(this.$form[0]);
    const attachmentData = this.parseAttachmentData(fileData);

    formData.append(this.$element.data('name'), attachmentData);

    $.ajax(this.$form.attr('action'), {
      contentType: false,
      processData: false,
      data: formData,
      method: this.$form.attr('method'),
      dataType: 'script',
    });
  }

  presignFile(fileData) {
    const options = {
      filename: fileData.files[0].name,
      _: Date.now(),
    };

    $.getJSON('/presign', options, (result) => {
      fileData.formData = result.fields;
      fileData.url = result.url;
      fileData.paramName = 'file';
      fileData.submit();
    });
  }

  parseAttachmentData(fileData) {
    const file = fileData.files[0];

    const attachmentData = {
      id: fileData.formData.key.match(/cache\/(.+)/)[1], // we have to remove the prefix part
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name.match(/[^/\\]+$/)[0], // IE return full path
        mime_type: file.type,
      },
    };

    return JSON.stringify(attachmentData);
  }

  validateFile(file) {
    return this.validateFileSize(file);
  }

  validateFileType(file) {
    const types = /(\.|\/)(avi|bmp|csv|dad|dar|ddr|dds|doc|docx|dwg|flv|gif|jpg|jpeg|mov|mp3|mp4|mpeg|mpg|msg|odt|pdf|png|ppt|pptx|txt|wav|wma|wmv|xls|xlsm|xlsx|zip|zipx)$/i;
    let response = true;

    if (!types.test(file.type) && !types.test(file.name)) {
      alert(`${file.name} must be one of the following file types: avi, bmp, csv, doc, docx, dwg, flv, gif, jpg, jpeg, mov, mp3, mp4, mpeg, mpg, msg, pdf, ppt, pptx, txt, wav, wma, wmv, xls, xlsm, xlsx, zip, zipx`);
      response = false;
    }

    return response;
  }

  validateFileSize(file) {
    let response = true;

    if (!(file.size < 5000 * 1024 * 1024)) {
      alert(`${file.name} is too large (max is 500 MB)`);
      response = false;
    }

    return response;
  }

  get $element() {
    return $(this.element);
  }

  get $form() {
    return this.$element.closest('form');
  }

  get $submit() {
    return this.$form.find('[type=submit]');
  }

  enableForm() {
    this.$submit.prop('disabled', false);
    this.$submit.html(this.enabledFormSubmitContent);
    this.$submit.css('cursor', 'pointer');
  }

  disableForm() {
    this.$submit.prop('disabled', true);
    this.enabledFormSubmitContent = this.$submit.html();
    this.$submit.html(this.$submit.data('disableWith'));
    this.$submit.css('cursor', 'default');
  }

  $progressBar(fileName) {
    return $(`<span class="text-sm">
         ${fileName}
       </span>
       <div class="mb-2">
         <div class="progress-bar bg-blue-400 text-xs leading-none py-1 pl-5 text-black shadow w-full bg-grey-light" style="width:0%;">
           0%
         </div>
       </div>`);
  }
}
