import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.state = 'closed'; // initial state
  }

  expand(event) {
    const { target } = event;
    if (target.tagName === 'A') return;

    if (this.state === 'closed') {
      this.open();
    } else if (this.state === 'open') {
      this.close();
    }
  }

  close(_target) {
    this.state = 'closing';

    $(this.containerTarget).hide(500, () => {
      this.state = 'closed';
    });
  }

  open(_target) {
    this.state = 'opening';

    $(this.containerTarget).show(500, () => {
      this.state = 'open';
    });
  }
}
