import { Controller } from 'stimulus';

export default class extends Controller {

  initialize() {
    console.log('Address filler controller initialized');
  }

  fill() {
    console.log('Fill has been run');

    const addressId = $(event.target).val();

    $.ajax({
      method: 'GET',
      url: `/settings/addresses/${addressId}`,
      dataType: 'json',
      success(data) {
        console.log(data.name);
        $('[name="sample_request[addr_line_1]"]').val(data.line_1)
        $('[name="sample_request[addr_line_2]"]').val(data.line_2)
        $('[name="sample_request[addr_line_3]"]').val(data.line_3)
        $('[name="sample_request[addr_city]"]').val(data.city)
        $('[name="sample_request[addr_province]"]').val(data.province)
        $('[name="sample_request[addr_state]"]').val(data.state)
        $('[name="sample_request[addr_postal_code]"]').val(data.postal_code)
        $('[name="sample_request[addr_country]"]').val(data.country)
      },
    });
  }

}
