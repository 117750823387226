import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.button = this.copyButton();
    this.input = this.hiddenInput();
    $(this.button).insertAfter(this.element);
    $(this.input).insertAfter(this.button);
    $(this.button).on('click', this.triggerCopy.bind(this));
    $(this.button).tooltip({ title: 'copy to clipboard', container: 'body' });
  }

  copyButton() {
    return $('<a>', { href: '#', class: 'ml-1' })
      .append($('<i>', { class: 'fas fa-copy text-gray-600 hover:text-gray-900' }));
  }

  hiddenInput() {
    return $('<input>', { type: 'text', style: 'opacity: 0; max-width: 1px;', value: this.element.innerText });
  }

  triggerCopy(e) {
    e.preventDefault();
    this.input.select();
    document.execCommand('copy');
  }
}
