import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['template'];

  initialize() {
    $(this.templateTarget).on('jChange', this._changeTemplate.bind(this));
  }

  _changeTemplate(event) {
    const { target } = event;
    const { value } = target;

    this.element.action = this.element.action.replace(/[:]id/, value);
    // $(this.element).submit();
    Rails.fire(this.element, 'submit');
  }
}
