import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['timestamp'];

  // Do not manually call this from an action
  submitForm() {
    if (this.data.get('event')) {
      if (this.emitted === true) {
        this.emitted = false;
        Rails.fire(this.element, 'submit');
        this.element.dispatchEvent(new Event('debounceable:submitted'));
      } else {
        this.emitted = true;
        this.element.dispatchEvent(new Event(this.data.get('event')));
      }
    } else {
      Rails.fire(this.element, 'submit');
    }
  }

  _submitFormJS(e) {
    if (e) e.preventDefault();
    if (!this.element.action.includes('.js')) this.element.action += '.js';
    this.submitForm();
    this.element.action = this.element.action.replace('.js', '');
    this._updateTimestamp();
  }

  _submitFormJSON(e) {
    if (e) e.preventDefault();
    if (!this.element.action.includes('.json')) this.element.action += '.json';
    this.submitForm();
    this.element.action = this.element.action.replace('.json', '');
    this._updateTimestamp();
  }

  _updateTimestamp() {
    if (!this.hasTimestampTarget) return;
    const time = Intl.DateTimeFormat('en',
      {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        second: 'numeric',
      }).format(new Date());

    this.timestampTarget.innerText = `last updated at ${time}`;
  }

  debouncedSubmitJSON = debounce(
    this._submitFormJSON.bind(this),
    this.data.get('debounceMs') || 1800000,
    { maxWait: this.data.get('debounceMaxWaitMs') || 1800000 },
  );

  debouncedSubmitJS = debounce(
    this._submitFormJS.bind(this),
    this.data.get('debounceMs') || 1800000,
    { maxWait: this.data.get('debounceMaxWaitMs') || 1800000 },
  );

  debouncedSubmit = debounce(
    this.submitForm.bind(this),
    this.data.get('debounceMs') || 1800000,
    { maxWait: this.data.get('debounceMaxWaitMs') || 1800000 },
  );

  // Set up some basic listeners to handle state for the entire controller.
  initialize() {
    // Whenever the form element submits by some method other than the debouncedSubmit,
    // we need to make sure we don't double submit.
    $(this.element).on('ajax:send', () => {
      this.debouncedSubmit.cancel();
    });
  }
}
