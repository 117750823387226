import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['select', 'selectAll', 'list', 'unseen', 'snoozed'];

  initialize() {
    window.console.log('Notifications Controller Initialized!');
  }

  connect() {
    this.initializeConsumer();
    this.updateUnseen();
  }

  disconnect() {
    this.updateUnseen();
  }

  selectAll(event) {
    const $target = $(event.target);
    this.toggleSelects($target.prop('checked'));
  }

  toggleSelects(state) {
    this.$selects.each((i, checkbox) => {
      $(checkbox).prop('checked', state);
    });
  }

  done() {
    this.$selects.each((i, checkbox) => {
      const $checkbox = $(checkbox);
      const $notificationElement = $checkbox.closest('tr');

      if ($checkbox.prop('checked')) {
        this.markAsDone($notificationElement);
      }
    });
  }

  markAsDone($notificationElement) {
    const notificationId = $notificationElement.data('id');

    $.ajax({
      method: 'PATCH',
      url: `/notifications/${notificationId}`,
      data: {
        notification: { done: true },
      },
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      success: () => {
        $(this.selectAllTarget).prop('checked', false);
        $notificationElement.detach();
        window.toastr.success('Notification/s done.');
      },
    });
  }

  snooze(event) {
    event.preventDefault();
    const $target = $(event.target);
    const duration = $target.data('value');

    this.$selects.each((i, checkbox) => {
      const $checkbox = $(checkbox);
      const $notificationElement = $checkbox.closest('tr');

      if ($checkbox.prop('checked')) {
        this.markAsSnoozed($notificationElement, duration);
      }
    });

    $target.closest('.limsey_dropdown').attr('x-data', '{ open: false }');
  }

  markAsSnoozed($notificationElement, duration) {
    const notificationId = $notificationElement.data('id');

    $.ajax({
      method: 'PATCH',
      url: `/notifications/${notificationId}`,
      data: {
        notification: { snooze: duration },
      },
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      success: () => {
        $(this.selectAllTarget).prop('checked', false);
        $notificationElement.detach();
        window.toastr.success('Notification/s snoozed.');
      },
    });
  }

  initializeConsumer() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: 'NotificationsChannel',
        id: this.userId,
      },
      {
        received: (data) => {
          this.add(data.notification_id);
        },
      },
    );
  }

  add(notificationId) {
    $.ajax({
      method: 'GET',
      url: `/notifications/${notificationId}`,
      dataType: 'html',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
      complete: (response) => {
        const html = response.responseText;
        this.$list.prepend(html);
      },
    });
  }

  updateUnseen() {
    $(this.unseenTargets).each((i, seenElement) => {
      const notificationElement = $(seenElement).closest('.notification');
      const notificationId = notificationElement.data('id');

      $.ajax({
        method: 'PATCH',
        url: `/notifications/${notificationId}`,
        data: { notification: { seen: true } },
        dataType: 'script',
        headers: { 'X-CSRF-Token': Rails.csrfToken() },
      });
    });
  }

  get $selects() {
    return $(this.selectTargets);
  }

  get userId() {
    return $(this.element).data('userId');
  }

  get $list() {
    return $(this.listTarget);
  }
}
